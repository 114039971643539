import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CareersService from "../services/CareersService";
import ImageUploadService from "../services/ImageUploadService";
import useStyles from "../style";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsCondition";
import DialogBox from "../components/DialogBox";
import Loader from "../components/Loader";
import CrossIcon from "../assets/cross-icon.svg";

export default function CareersForm() {
  const classes = useStyles();

  const [candidateData, setCandidateData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    city: "",
    email: "",
    mobile: "",
    comments: "",
    filePath: "",
    privacyAccepted: false,
  });

  const [resume, setResume] = useState("");
  const [resumeValue, setResumeValue] = useState("");
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alertOpen, setAlertOpen] = useState(true);
  const [errorMsg, setErrorMsg] = useState(
    " Please fill all the required details!"
  );
  const [firstNameError, setFirstNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [commentsError, setCommentsError] = useState(false);
  const [privacyCheckedError, setPrivacyCheckedError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [cvError, setCVError] = useState(false);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCandidateData({ ...candidateData, [name]: value });
  };

  const handlePrivacyAccepted = (event) => {
    setCandidateData({
      ...candidateData,
      privacyAccepted: event.target.checked,
    });
  };

  const handleCVChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (
      !file ||
      file.size > 5242880 ||
      !(file.type.includes("pdf") || file.type.includes("document"))
    ) {
      //5248256
      setCVError(true);
      setResume("");
      setResumeValue("");
    } else {
      setResume(file);
      setResumeValue(event.target.value);
    }
  };
  const clearContactDetails = () => {
    setCandidateData({
      firstName: "",
      lastName: "",
      gender: "",
      city: "",
      email: "",
      mobile: "",
      comments: "",
      filePath: "",
      privacyAccepted: false,
    });
    setResumeValue("");
  };

  const validateForm = () => {
    let isError = false;
    if (candidateData.firstName === "") {
      isError = true;
      setFirstNameError(true);
    }
    if (candidateData.mobile === "") {
      isError = true;
      setMobileError(true);
    }
    if (candidateData.email === "") {
      isError = true;
      setEmailError(true);
    }
    if (candidateData.city === "") {
      isError = true;
      setCityError(true);
    }
    if (candidateData.gender === "") {
      isError = true;
      setGenderError(true);
    }
    if (candidateData.comments === "") {
      isError = true;
      setCommentsError(true);
    }
    if (candidateData.privacyAccepted === false) {
      isError = true;
      setPrivacyCheckedError(true);
    }
    if (resume === "" || null) {
      isError = true;
      setCVError(true);
    }
    return isError;
  };

  useEffect(() => {
    if (candidateData.filePath !== "" && !error) {
      CareersService.saveCandidateEmployeeData(candidateData)
        .then(() => {
          clearContactDetails();
          console.log("Data saved successfully");
        })
        .catch(() => {
          setError(true);
          setErrorMsg("Something went wrong! Please try again.");
          console.log("Failed to save data");
        });
      CareersService.sendMailtoUser(candidateData)
        .then(() => {
          console.log("Mail sent successfully");
          setSuccess(true);
        })
        .catch(() => {
          console.log("Failed to send mail");
          setError(true);
          setErrorMsg("Something went wrong! Please try again.");
        });
      CareersService.sendInternalMail(candidateData)
        .then(() => {
          console.log("Internal Mail sent successfully");
          setBtnDisabled(false);
          setShowLoader(false);
          setSuccess(true);
        })
        .catch(() => {
          console.log("Failed to send mail");
          setBtnDisabled(false);
          setShowLoader(false);
        });
    }
  }, [candidateData.filePath]);

  const handleSubmit = async (event) => {
    //console.log("candidateData: ", candidateData);
    event.preventDefault();
    let isError = validateForm();
    setError(isError);
    setAlertOpen(false);
    if (!isError) {
      setBtnDisabled(true);
      setShowLoader(true);
      await handleImageUpload();
    }
  };

  const handleImageUpload = async () => {
    if (!error) {
      let formData = new FormData();
      formData.append("fnm", "Others");
      formData.append("mdl", 8);
      formData.append("file", resume);
      formData.append("limg", resume.name);
      console.log("Resume: ", resume);
       console.log("Uploading Image.....");
      await ImageUploadService.upload(formData)
        .then((response) => {
          console.log("imgResc: ", response.data);
          setCandidateData({
            ...candidateData,
            filePath: response.data.folderName,
          });
        })
        .catch(() => {
          //console.log("Failed to upload image");
          setError(true);
          setErrorMsg("Failed to Upload CV! Please try again.");
          setBtnDisabled(false);
          setShowLoader(false);
        });
    }
  };

  const renderDialogContent = () => {
    if (showPrivacy) {
      return (
        <React.Fragment>
          <PrivacyPolicy />
        </React.Fragment>
      );
    } else if (showTerms) {
      return (
        <React.Fragment>
          <TermsConditions />
        </React.Fragment>
      );
    }
  };
  const renderDialogActions = () => {
    return (
      <Button variant="contained" color="primary" onClick={handleDialogClose}>
        Close
      </Button>
    );
  };
  const handleDialogClose = () => {
    setShowTerms(false);
    setShowPrivacy(false);
  };
  return (
    <React.Fragment>
      <form className={classes.contactForm}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label="First name"
              size="small"
              value={candidateData.firstName}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              onFocus={() => {
                setError(false);
                setFirstNameError(false);
              }}
              error={firstNameError}
              //autoComplete="given-name"
            />
            <TextField
              required
              id="mobile"
              name="mobile"
              label="Mobile No."
              size="small"
              value={candidateData.mobile}
              onChange={handleChange}
              onFocus={() => {
                setError(false);
                setMobileError(false);
              }}
              error={mobileError}
              variant="outlined"
              fullWidth
              //autoComplete="shipping country"
            />
            <TextField
              required
              id="gender"
              name="gender"
              label="Gender"
              size="small"
              select
              value={candidateData.gender}
              onChange={handleChange}
              onFocus={() => {
                setError(false);
                setGenderError(false);
              }}
              error={genderError}
              fullWidth
              variant="outlined"
              helperText="Please Select Your Gender"
              //autoComplete="family-name"
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label="Last name"
              value={candidateData.lastName}
              onChange={handleChange}
              variant="outlined"
              size="small"
              fullWidth
              //autoComplete="family-name"
            />

            <TextField
              required
              id="city"
              name="city"
              label="Current City"
              size="small"
              value={candidateData.city}
              onChange={handleChange}
              onFocus={() => {
                setError(false);
                setCityError(false);
              }}
              error={cityError}
              fullWidth
              variant="outlined"
              //autoComplete="family-name"
            />
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              type="email"
              size="small"
              value={candidateData.email}
              onChange={handleChange}
              onFocus={() => {
                setError(false);
                setEmailError(false);
              }}
              error={emailError}
              variant="outlined"
              fullWidth
              //autoComplete="shipping postal-code"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="comments"
              name="comments"
              label="Why you wants to join SNP Next Gen ?"
              value={candidateData.comments}
              onChange={handleChange}
              onFocus={() => {
                setError(false);
                setCommentsError(false);
              }}
              error={commentsError}
              fullWidth
              variant="outlined"
              multiline
              rowsMax={5}
            />

            <TextField
              accept="*"
              // className={classes.input}
              id="contained-button-file"
              name="icon"
              label="Icon"
              size="small"
              multiple
              type="file"
              value={resumeValue}
              onChange={handleCVChange}
              onFocus={() => {
                setError(false);
                setCVError(false);
              }}
              error={cvError}
              helperText="Please select your CV (Only .docx or .pdf of size below 5 MB)"
              required
            />
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={candidateData.privacyAccepted}
                    onChange={handlePrivacyAccepted}
                    onFocus={() => {
                      setError(false);
                      setPrivacyCheckedError(false);
                    }}
                    error={privacyCheckedError}
                    name="privacyAccepted"
                    color="primary"
                    className={classes.checkbox}
                  />
                }
                label={
                  <div className={classes.checkboxLabel}>
                    <span>I accept the </span>
                    <button
                      type="button"
                      onClick={() => {
                        setShowPrivacy(true);
                      }}
                    >
                      Privacy Policy
                    </button>
                    <span> and </span>
                    <button
                      type="button"
                      onClick={() => {
                        setShowTerms(true);
                      }}
                    >
                      Terms & Condition
                    </button>
                  </div>
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            {showLoader && <Loader />}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={btnDisabled}
            >
              Submit
            </Button>
            <br />
            {error && (
              <Alert variant="outlined" severity="error">
                {errorMsg}
              </Alert>
            )}
            {success && (
              <Collapse in={alertOpen}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlertOpen(false);
                      }}
                    >
                      {/* <CloseIcon fontSize="inherit" /> */}
                      <img
                        alt="SNP"
                        src={CrossIcon}
                        height="18px"
                        width="22px"
                        style={{ filter: "invert(0.8)" }}
                      />
                    </IconButton>
                  }
                >
                  Thanks for Contacting!
                </Alert>
              </Collapse>
            )}
          </Grid>
        </Grid>
        <DialogBox
          showDialog={showPrivacy || showTerms}
          dialogContent={renderDialogContent()}
          dialogActions={renderDialogActions()}
          onClose={handleDialogClose}
        ></DialogBox>
      </form>
    </React.Fragment>
  );
}
