import React from "react";
import { Grid } from "@material-ui/core";
import styles from "../scss/style.module.css";
import useStyles from "../style";
import { workItems } from "./workItems";
import WorkItem from "./WorkItem";

function Works() {
  const classes = useStyles();
  return (
    <div className={styles.workSection}>
      <h2 className={styles.sectionTitle}>Our Works</h2>
      <Grid container spacing={2}>
        {workItems.map((item,index) => {
          return (
            <Grid item xs={12} sm={12} md={6} lg={4} key={item.title}>
              <WorkItem
                title={item.title}
                content={item.content}
                image={item.image}
                url={item.url}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default Works;
