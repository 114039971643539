import { Grid } from "@material-ui/core";
import React from "react";
import styles from "../scss/style.module.scss";
import image from "../assets/franchise.jpg";
const Franchise = () => {
  return (
    <div className={styles.franchiseSection}>
      <h2 className={styles.sectionTitle}>Franchise</h2>
      <Grid container>
        <Grid item md={6} lg={6} sm={12}>
          <div className={styles.sectionImageWrapper}>
            <img alt="" className={styles.sectionImage} src={image} />
          </div>
        </Grid>
        <Grid item md={6} lg={6} sm={12}>
          <div className={styles.sectionTextWrapper}>
            <p className={styles.sectionContent}>
              {" "}
              We as a company have a vast platform and are reputed between
              peoples for our products. We provide opportunities to other
              Businessmen around the world who would be ready to follow our
              values.
            </p>
            <p className={styles.sectionContent}>
              We as a company follow our values as an enduring and refreshing
              soul which brings us to provide equal opportunity and together
              sustainable approach to everyone's development in this world. This
              feature of ours brings different ethnic groups with different
              approaches to jointly work in a collaborative approach and
              transport the flavour and fragrance in society.
            </p>
            <p className={styles.sectionContent}>
              Our values and technical decentralisation provide us joint
              capability to spread our product in their zone, area, region,
              ethics and country to their level.
            </p>
            <p className={styles.sectionContent}>
              Businessmen who are interested in our product's marketing can
              reach us with their plan and we would provide a transparent
              platform for our (we and businessmen) development and together
              help in society upliftment.
            </p>
            <p className={styles.sectionContent}>
              Businessmen can reach us with their thoughts and plan and contact
              us over franchise@snpnextgen.com and the concerned team will share
              a few info and queries, based on a response call will be setup
              with concerned authority and spokesperson.
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Franchise;
