import { Grid } from "@material-ui/core";
import React from "react";
import styles from "../scss/style.module.scss";
import image from "../assets/our_journey.jpg";

const OurJourney = () => {
  return (
    <div className={styles.ourJourneySection} id="ourJourney">
      <h2 className={styles.sectionTitle}>Our Journey</h2>
      <Grid container >
        <Grid item md={6} sm={12} className={styles.sectionTextContainer}>
          <div className={styles.sectionTextWrapper}>
            <p className={styles.sectionContent}>
              We are new to the world as per our incorporation but our journey
              started in mid of 2013 when our Team came across a few ideas and
              we kick-off harvesting it.
            </p>
            <p className={styles.sectionContent}>
              In the year 2015 we built multiple platforms and started modelling
              to build ideas over built platforms and in late 2016 we finalized
              the product emulating platform.
            </p>
            <p className={styles.sectionContent}>
              In late 2017 we started testing technically if the products are
              able to be modelled as per selected platform and we identified
              three products feasible and the rest two need other platforms. We
              majorly contributed towards the ideation, inclusion and business
              viability and their real time constraint. We indulge in multiple
              re-work to bring equilibrium between values and business
              viability.
            </p>
            <p className={styles.sectionContent}>
              In 2018 we conducted a survey and took the details from
              customers/business to understand their aspiration and their
              cooperation was phenomenal, we identified their challenges and
              requirements, then we started modelling its business aspects and
              in early 2019 we built the business model with precision keeping
              our values intact.
            </p>
            <p className={styles.sectionContent}>
              In late 2019, we gave go-ahead for development of products and in
              mid of 2020 the products were about to be ready for testing. But
              then we and our company and even everywhere faced the deadly
              Covid-19 which shut-down our development for a while.
            </p>
            <p className={styles.sectionContent}>
              Our leaders worked-out and then we suggested our Project,
              Marketing team to incorporate Agile concept of working, in a few
              months we created infrastructure and re-calibrated the work which
              brought back the zeal of development and by late 2020 we were
              back-on-track.
            </p>
            <p className={styles.sectionContent}>
              During late 2020 our marketing team conducted a survey for a
              product we developed which was going to launch in the market in
              the coming year. They reached to the new as well as old
              Customer/Business approaches during the previous survey and
              received lots of applause when their thoughts incorporated. It's
              not like we receive positive feedback but negative feedback also
              and we take all their thoughts into account and whichever their
              constructive thoughts will be included in new development or
              existing upgrade to our product in future.
            </p>
          </div>
        </Grid>
        <Grid item md={6} sm={12} className={styles.sectionImageContainer}>
          <div className={styles.sectionImageWrapper}>
            <img alt="" className={styles.sectionImage} src={image} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OurJourney;
