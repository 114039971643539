import { Grid } from "@material-ui/core";
import React from "react";
import styles from "../scss/style.module.scss";
import image from "../assets/about_us.jpg";
const About = () => {
  return (
    <div className={styles.aboutSection} id="about">
      <h2 className={styles.sectionTitle}>About</h2>
      <Grid container>
        <Grid item md={6} sm={12} className={styles.sectionImageContainer}>
          <div className={styles.sectionImageWrapper}>
            <img alt="" className={styles.sectionImage} src={image} />
          </div>
        </Grid>
        <Grid item md={6} sm={12}>
          <div className={styles.sectionTextWrapper}>
            <p className={styles.sectionContent}>
              Welcome to SNP Pvt. Ltd, we are the Technocrats and
              Business-builders who work in different arenas to bring the idea
              to fly in this world. We have (I-I-I Group) Interoperable,
              Interdependent and Intermodal group which convert the IDEAS into a
              Shape (i.e. Products) and then we add flavours of marketing which
              impart the product a theme and product converted into jargon which
              start its journey to deliver the fragrance around the world.
            </p>
            <p className={styles.sectionContent}>
              Social development, Equality and elevation of society are
              paramount for us and we put in a lot of effort such that the
              product should embrace these pillars.
            </p>
            <p className={styles.sectionContent}>
              We know any development comes with certain limitation but we never
              compromise our ‘core-values’ and this attitude discover the
              product which reach each segment of society but this invention
              credit diaphragm has two segment one ‘Customer positive/negative
              feedback during survey’ and other our ‘Enthusiastic Team’.
            </p>
            <p className={styles.sectionContent}>
              We majorly deal with development of Mobile, Software/Computer
              application, Electronic Application and Product, Factory for
              production of raw materials of small scale industry and not the
              last we have R&D department which choose the selected ideas and
              then convert into the Product based on business demands.
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default About;
