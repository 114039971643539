import axios from "axios";
import {
  BASE_JCU_SITE_SERVICE_URL,
  JCU_MAIL_SENDER_API_ENDPOINT,
  //JCU_MAIL_SERVIC_URL,
  SAVE_CONTACTUS_DATA_ENDPOINT,
} from "../constants/Constants";

class ContactUsService {
  sendInternalMail(formData) {
    //return axios.post(JCU_MAIL_SERVIC_URL,{...formData,mailType:"RewardsInternalMail"});
    const message = `<html><body> Hi Team,<br/><br/> We have a new contact request. Please find the details below:  
	                <table rules="all" style="border-color: #666;" cellpadding="10">
	                <tr><td><strong>Name:</strong> </td><td>${formData.firstName} ${formData.lastName}</td></tr>
                    <tr><td><strong>Email:</strong> </td><td>${formData.email} </td></tr>
                    <tr><td><strong>Mobile:</strong> </td><td> ${formData.mobile} </td></tr>
                    <tr><td><strong>Comment Type:</strong> </td><td> ${formData.commentsType} </td></tr>
                    <tr><td><strong>Comment:</strong> </td><td> ${formData.comments} </td></tr>
                    <tr><td><strong>Privacy Accepted:</strong> </td><td>${formData.privacyAccepted}</td></tr>
                    </table></body></html>`;

                    

    const mailData = {
      jcJson: {
        //tos: "amankr.snp1@gmail.com",
        tos: "query@snp.justconnectunite.com",
        ccs: "",
        subject: `Contact request from ${formData.firstName}`,
        body: message,
        attachmentpath: "",
      },
    };
    return axios.post(JCU_MAIL_SENDER_API_ENDPOINT, mailData,{timeout: 5000});
  }

  sendMailToUser(formData) {
    // return axios.post(JCU_MAIL_SERVIC_URL, {...formData,mailType:"ContactUserMail"});
    const message = `<html><body> Dear ${formData.firstName},<br/><br/>
                    Thanks for contacting us. We have received your contact request. Our team will get back to you sortly.
                    <br/><br/>Best Regards,<br/>SNP Next Gen Supports Team <br/>
                    Please Like, Follow, Subscribe on Social Platform for latest updates</br>
                    <a href="https://www.youtube.com/channel/UCwQCwZvSeTDGtIE5rPOLaKg">Youtube</a>
                    <a href="https://www.facebook.com/Just-Connect-Unite-101868548789106">Facebook</a>
                    <a href="https://instagram.com/justconnectunite_snp?utm_medium=copy_link">Instagram</a>
                    </body></html>`;

    const mailData = {
      jcJson: {
        tos: formData.email,
        ccs: "",
        subject: `SNP Nexxtgen - Contact Request Confirmation!`,
        body: message,
        attachmentpath: "",
      },
    };
    return axios.post(JCU_MAIL_SENDER_API_ENDPOINT, mailData, {timeout: 5000});
  }
  saveUserContactDetailsData(formData) {
    return axios.post(
      BASE_JCU_SITE_SERVICE_URL + SAVE_CONTACTUS_DATA_ENDPOINT,
      formData,
      {timeout: 5000}
    );
  }
}

export default new ContactUsService();
