import { Button, Grid } from "@material-ui/core";
import React from "react";
import styles from "../scss/style.module.scss";
import image from "../assets/careers.jpg";
const Careers = () => {
  return (
    <div className={styles.careerSection} id="careers">
      <h2 className={styles.sectionTitle}>Career</h2>
      <Grid container>
        <Grid item md={6} lg={6} sm={12} className={styles.sectionImageContainer} >
        <div className={styles.sectionImageWrapper}>
            <img alt="" className={styles.sectionImage} src={image} />
          </div>
        </Grid>
        <Grid item md={6} lg={6} sm={12} >
        <div className={styles.sectionTextWrapper}>
          <p className={styles.sectionContent}>
            We are majorly contributing as R & D in the multilateral segment and
            it's a favourable place for those who want to evolve themselves and
            bring new Technology to society. We are dedicated to helping
            organizations in R & D to renew their core and simultaneously
            innovate into new frontiers. As we are new in the market and as the
            company settles a renounced identity, the employer gets lots of
            opportunities to reach high.
          </p>
          <p className={styles.sectionContent}>
            Whilst we constantly challenge convention, some things remain
            unchanged: the unwavering ethics, transparency and respect behind
            everything we do. We will always be a company powered by intellect
            and driven by values.
          </p>
          <p className={styles.sectionContent}>
            So, if your passion is to build solutions that really make a
            difference to enterprises, the community and your world, SNP Pvt.
            Ltd. is the right place for you.
          </p>
          <p className={styles.sectionContent}>
            For more information about SNP Pvt. Ltd or career opportunities
            please visit us @ www.snpnextgen.com
          </p>
          <p className={styles.sectionContent}>
            SNP Pvt. Ltd. values diversity and inclusion and is committed to the
            principles of Equal Employment Opportunity.
          </p>
          <p className={styles.sectionContent}>
            SNP Pvt. Ltd. will endeavor to make a reasonable accommodation to
            the known physical or mental limitations of a qualified applicant
            with a disability unless the accommodation would impose an undue
            hardship on the operation of our business. If you believe you
            require such assistance to complete this form or to participate in
            an interview, please contact us at
            disability_Helpdesk@snpnextgen.com.
          </p>
          <p className={styles.sectionContent}><Button onClick={()=> window.location.href="/careers"}>Click here!</Button> to join us</p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Careers;
