import { Grid, IconButton, Link } from "@material-ui/core";
import React from "react";
import styles from "../scss/style.module.scss";
import youtube from "../assets/Youtube.svg";
import facebook from "../assets/Facebook.svg";
import insta from "../assets/Instagram.svg";
import twitter from "../assets/Twitter.svg";
import email from "../assets/email.svg";
import mobile from "../assets/mobile.svg";
import location from "../assets/location-pin.svg";

function Footer() {
  return (
    <div className={styles.footer}>
      <Grid container spacing={3} className={styles.footerUp}>
        <Grid item md={4} sm={12} xs={12} className={styles.footerAbout}>
          <h2>Spark N Pass Next Gen</h2>
          <p>
            We are fast growing organigation making daily life simple for
            individuals and business.
          </p>
          <div>
            <IconButton
              onClick={() => (window.location.href = "/")}
              className={styles.footerSocialIcon}
            >
              <img height="30px" width="30px" alt="Facebook" src={facebook} />
            </IconButton>
            <IconButton
              onClick={() => (window.location.href = "/")}
              className={styles.footerSocialIcon}
            >
              <img height="30px" width="30px" alt="Facebook" src={twitter} />
            </IconButton>
            <IconButton
              onClick={() => (window.location.href = "/")}
              className={styles.footerSocialIcon}
            >
              <img height="30px" width="30px" alt="Facebook" src={insta} />
            </IconButton>
            <IconButton
              onClick={() => (window.location.href = "/")}
              className={styles.footerSocialIcon}
            >
              <img height="30px" width="30px" alt="Facebook" src={youtube} />
            </IconButton>
          </div>
        </Grid>
        <Grid item md={2} sm={6} xs={6} className={styles.footerLinks}>
          <h3>Quick Links</h3>
          <Link color="inherit" href="/#about">
            About
          </Link>
          <Link color="inherit" href="/investor">
            Investor
          </Link>
          <Link color="inherit" href="/franchise">
            Franchise
          </Link>
          <Link color="inherit" href="/#ourJourney">
            Our Journey
          </Link>
          <Link color="inherit" href="/#careers">
            Careers
          </Link>
        </Grid>
        <Grid item md={2} sm={6} xs={6} className={styles.footerBusiness}>
          <h3>Our Business</h3>
          <Link color="inherit" href="/it">
            IT Industry
          </Link>
          <Link color="inherit" href="/electronics">
            Electronics Application Industry
          </Link>
          <Link color="inherit" href="/green-gas">
            Green Gas Industry
          </Link>
        </Grid>
        <Grid item md={4} sm={12} xs={12} className={styles.footerContact}>
          <h3>Reach Us</h3>
          <div className={styles.footerContactBox}>
            <img alt="" className={styles.footerContactIcon} src={email} />
            <p className={styles.footerContactText}>support@snpnextgen.com</p>
          </div>
          <div className={styles.footerContactBox}>
            <img alt="" className={styles.footerContactIcon} src={mobile} />
            <p className={styles.footerContactText}>(+91) 8541080907</p>
          </div>
          <div className={styles.footerContactBox}>
            <img alt="" className={styles.footerContactIcon} src={location} />
            <p className={styles.footerContactText}>
              <span>
                <b>Corporate Office: </b>
              </span>
              Sarjapur Main Road, Jakkasandra, Koramangala, Bengaluru,
              Karnataka(India)-560047
            </p>
          </div>
          <div className={styles.footerContactBox}>
            <img alt="" className={styles.footerContactIcon} src={location} />
            <p className={styles.footerContactText}>
              <span>
                <b>Registered Office:</b>{" "}
              </span>
              New Market, Saraiyaganj, Muzaffarpur, Bihar(India)-842001
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={styles.footerBottom} >
        <Grid item md={4} style={{display: "flex", justifyContent: "center" }}>
          <Link color="inherit" href="/privacy-policy">
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link color="inherit" href="/terms-condition">
            Terms & Condition
          </Link>{" "}
          |{" "}
          <Link color="inherit" href="/terms-condition">
            Disclaimer
          </Link>
        </Grid>
        <Grid item md={4} style={{display: "flex", justifyContent: "center" }}>
        <a href="https://www.hitwebcounter.com" target="_blank">
            <img
              src="https://hitwebcounter.com/counter/counter.php?page=7943357&style=0006&nbdigits=9&type=page&initCount=0"
              title="Free Counter"
              Alt="web counter"
              border="0"
            />
          </a>
        </Grid>
        <Grid
          item
          md={4}
          style={{display: "flex", justifyContent: "center" }}
        >
          <span>
            © 2021 Spark n Pass Next Gen Pvt. Ltd., All rights reserved
          </span>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;
