import React from "react";
import { Container } from "@material-ui/core";
import styles from "../scss/style.module.scss";

export default function PrivacyPolicy() {
  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <div className={styles.privacySection}>
          <h2 className={styles.sectionTitle}>Privacy Policy</h2>
          <div className={styles.privacyTextWrapper}>
            <h3>Who is covered under this policy?</h3>
            <p className={styles.sectionContent}>
              All natural persons ("Covered Persons"), whose personal
              information is either collected/received/possessed/stored/dealt
              in/handled by Just Connect Unite App/website who visit the site{" "}
              <a href="http://www.justconnectunite.com">
                http://www.justconnectunite.com
              </a>{" "}
              and provide information to Just Connect Unite App/website online,
              are covered under this Policy.
            </p>
            <h3>Information covered by this Policy </h3>
            <p className={styles.sectionContent}>
              This Policy seeks to cover personal information of the Covered
              Persons provided to Just Connect Unite App/website as also any
              information collected by the Just Connect Unite App/website server
              from the visitor’s browser. The ("Information"), i.e. any of the
              following:
            </p>
            <ul>
              <li>Personal/private information of the Covered Persons</li>
              <li>Sensitive personal data or information</li>
            </ul>
            <h3>Definitions used in this policy: </h3>
            <p className={styles.sectionContent}>
              "Personal information" means any information that relates to a
              natural person, which, either directly or indirectly, in
              combination with other information available or likely to be
              available with a body corporate, is capable of identifying such
              person. "Sensitive personal data or information" of a person means
              such personal information which consists of information relating
              to:
            </p>
            <ul>
              <li>Password</li>
              <li>
                Financial information such as bank account or credit card or
                debit card or other payment instrument details;
              </li>
              <li>Physical, physiological and mental health condition;</li>
              <li>Sexual orientation;</li>
              <li>Medical records and history;</li>{" "}
              <li>Biometric information;</li>
            </ul>
            <p className={styles.sectionContent}>
              Provided that, any information that is freely available or
              accessible in public domain or furnished under the Right to
              Information Act, 2005 or any other law for the time being in force
              shall not be regarded as sensitive personal data or information
              for these purposes.
            </p>
            <h3>The Features of the Policy:</h3>
            <p className={styles.sectionContent}>
              All Information collected shall be used for the relevant lawful
              purposes connected with various functions or activities of the
              Just Connect Unite App/website related to services in which the
              Concerned Person is interested, and/or to help determine the
              eligibility of the Concerned Persons for the product/services
              requested/ applied/ shown interest in and/or to enable Just
              Connect Unite App/website the Covered Persons verification and/or
              process applications, requests, transactions and/or maintain
              records as per internal/legal/regulatory requirements and shall be
              used to provide the Concerned Person with the best possible
              services/products as also to protect interests of Just Connect
              Unite App/website.
            </p>
            <p className={styles.sectionContent}>
              The Information shall not be shared with any external organisation
              unless the same is necessary to protect the interests of the Just
              Connect Unite App/website or to enable, or the same is necessary
              or required pursuant to applicable Just Connect Unite
              App/websiteing norms or pursuant to the terms and conditions
              applicable to such Information as agreed to with Just Connect
              Unite App/website or pursuant to any requirement of
              law/regulations or any Government/court/other relevant authority’s
              directions/orders. Needless to add, confidentiality norms as
              applicable to Just Connect Unite App/websites shall be adhered to.
              Just Connect Unite App/website may also share Information to
              provide you with superior services and a range of offers.
            </p>
            <p className={styles.sectionContent}>
              We may also share your Information, without obtaining your prior
              written consent, with government agencies mandated under the law
              to obtain information for the purpose of verification of identity,
              or for prevention, detection, investigation including cyber
              incidents, prosecution, and punishment of offences, or where
              disclosure is necessary for compliance of a legal obligation. Any
              Information may be required to be disclosed to any third party by
              us by an order under the law for the time being in force.
            </p>
            <p className={styles.sectionContent}>
              In this regard, it may be necessary to disclose the Covered
              Persons information to one or more agents and contractors of Just
              Connect Unite App/website and their sub-contractors, but such
              agents, contractors, and sub-contractors will be required to agree
              to use the information obtained from Just Connect Unite
              App/website only for these purposes. Information provided by you
              are retained (for later of the) (i) as long as the purposes for
              which such data were collected continue. Or (ii) for such period
              so as to satisfy legal, regulatory or accounting requirements or
              to protect Just Connect Unite App/website's interests. Please note
              that the accuracy of the Information provided to us on the Website
              is essential, among others, to provision of our products and
              services to you. It is therefore a term and condition governing
              the access and use of the Website that you undertake to ensure the
              accuracy and completeness of all Information disclosed, shared,
              exchanged or otherwise update and notify the Just Connect Unite
              App/website via e-mail a
              <b> privacy_policy@snp.justconnectunite.com</b> of any changes in
              the Information.
            </p>
            <p className={styles.sectionContent}>
              The Covered Persons authorises Just Connect Unite App/website to
              exchange, share, part with all information related to the details
              to its Affiliates / Just Connect Unite App/websites /
              agencies/participation in any telecommunication as may be required
              by law, customary practice, Just Connect Unite App/website liable
              for use or disclosure of this information.
            </p>
            <p className={styles.sectionContent}>
              The Covered Persons shall not disclose to any other person, in any
              manner whatsoever, any information relating to Just Connect Unite
              App/website or its Affiliates of a confidential nature obtained in
              the course of availing the services through the website. Failure
              to comply with this obligation shall be deemed a serious breach of
              the terms herein and shall entitle Just Connect Unite App/website
              or its Affiliates to terminate the services, without prejudice to
              any damages, to which the Covered Persons may be entitled
              otherwise.
            </p>
            <p className={styles.sectionContent}>
              As regards the information collected from visitors of the website
              online (“visitor”), Just Connect Unite App/website will use the
              Information to improve the Covered Persons experience on the site
              and make subsequent offers to the visitor on products which may be
              of interest to him / her, if so agreed while giving information.
            </p>
            <p className={styles.sectionContent}>
              The Just Connect Unite App/website website uses cookies. Cookies
              are small data files that a website stores on your computer. We
              use persistent cookies which are permanently placed on your
              computer to store non-personal (Browser, ISP, OS, Clickstream
              information etc) and profiling information (age, gender, income
              etc). While cookies have unique identification nos, personal
              information (name, a/c no, contact nos etc) SHALL NOT be stored on
              the cookies.
            </p>
            <p className={styles.sectionContent}>
              We will use the information stored in the cookies to improve
              visitor experience through throwing up relevant content where
              possible. We will also use the cookies to store visitor
              preferences to ease visitor navigation on the site. We may in the
              future implement encryption of the cookies. Just Connect Unite
              App/website also may disclose information about you as permitted
              or required by law. At Just Connect Unite App/website, we value
              your relationship and will at all times strive to ensure your
              privacy. The Just Connect Unite App/website may, from time to
              time, change this policy.
            </p>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}
