import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // link: {
  //   margin: theme.spacing(1, 1.5),
  // },

  mobileNavIcon: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      height: 28,
      width: 32,
      margin: "auto",
    },
  },
  navBtn: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    marginLeft: "20px",
    borderRadius: "30px",
    backgroundColor: "#047903",
    color: "white",
    "&:hover": {
      backgroundColor: "#035f06",
    },
  },

  contactForm: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "85%",
    },
    "& .MuiFormLabel-root": {
      marginLeft: "10px",
    },
    "& .MuiFormGroup-root": {
      marginLeft: "10px",
    },
    "& .MuiButton-root": {
      margin: "25px 10px",
    },
  },

  checkbox: {
    [theme.breakpoints.down("sm")]: {
      alignSelf: "start",
    },
    display: "flex",
    alignSelf: "center",
    padding: "4px 9px",
  },
  checkboxLabel: {
    cursor: "default",
    "& button": {
      color: "rgba(1,51,62,1)",
      margin: "1px",
      fontSize: "16px",
      backgroundColor: "rgba(0, 0, 0, 0.15)",
      borderColor: "rgba(0, 0, 0, 0.02)",
      textDecoration: "none",
      padding: "5px",
      borderRadius: "5px",
    },
    "& button:hover": {
      cursor: "pointer",
      textDecoration: "none",
      color: "white",
      backgroundColor: "rgba(1,51,62,0.9)",
    },
  },

  productItem: {
    display: "flex",
    justifyContent: "center",
    
    "& .MuiCardActionArea-root": {
      display: "flex",
      flexDirection: "column",
      padding: "10px",
    },
    "& .MuiCard-root": {
      width: 300,
      margin: "20px 0px",
    },
    "& .MuiCardMedia-img": {
      maxWidth: "100px",
      borderRadius: "30px",
    },
  },
}));

export default useStyles;
