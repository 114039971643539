import { Grid } from "@material-ui/core";
import React from "react";
import styles from "../scss/style.module.scss";
import image from "../assets/careers.jpg";
import CareerForm from "./CareerForm";
const CareersHome = () => {
  return (
    <div className={styles.careerSection} id="careers">
      <h2 className={styles.sectionTitle}>Career</h2>
      <Grid container>
        <Grid
          item
          md={6}
          lg={6}
          sm={12}
          className={styles.sectionImageContainer}
        >
          <div className={styles.sectionImageWrapper}>
            <img alt="" className={styles.sectionImage} src={image} />
          </div>
        </Grid>
        <Grid item md={6} lg={6} sm={12}>
          <CareerForm />
        </Grid>
        <Grid item xs={12}>
          <div>
            <h3>Recruitment Fraud Alert</h3>
            <p className={styles.sectionContent}>
              We would like to bring to your notice that SNP Pvt. Ltd. follows a
              merit-based employee recruitment practice with extensive screening
              steps. SNP Pvt. Ltd. does not charge/accept any amount or security
              deposit from job seekers during the recruitment process.
            </p>
            <p className={styles.sectionContent}>
              It was observed that there has been an increase in recruitment
              fraud involving scammers who post fraudulent jobs openings, or who
              contact job-seekers with fake job offers. These individuals
              sometimes even conduct fraudulent interviews with an attempt to
              obtain personal information or money from the applicants. We have
              also noticed that certain individuals claiming to be from the SNP
              Pvt. Ltd. Recruitment functions are contacting prospective
              candidates pretending to represent SNP Pvt. Ltd. with job offers.
              In case you or any candidate receives any unsolicited or
              fraudulent communication regarding a job offer or an interview
              call against payment of money, please stay alert and recognize it
              as a scam. On receipt of an interview call, to prevent falling
              victim to the same, the candidate may take some measures such as
              visiting the official SNP Pvt. Ltd. website to verify the source
              and authenticity.
            </p>
            <h3>
              <b>Disclaimer: How to identify Recruitment Fraud</b>
            </h3>
            <ul>
              <li>
                {" "}
                SNP Pvt. Ltd. never requests for fees for any purpose during or
                after the hiring process. SNP Pvt. Ltd. will never request money
                for the opportunity to apply or work.
              </li>
              <li>
                The hiring process involves at least one in-person interview at
                SNP Pvt. Ltd. office premises. SNP Pvt. Ltd. will not request
                for an interview on any instant messaging applications.
              </li>
              <li>
                The SNP Pvt. Ltd. Recruitment team corresponds from email
                addresses with the domain @snpnextgen.com. However, there is
                also a possibility of scammers to display the sent email domain
                address as @snpnextgen.com fraudulently. Nevertheless, receiving
                emails will be possible only with the legitimate @snpnextgen.com
                domain. In case any generic email ID ending with Gmail/Yahoo
                domain is copied while receiving a job offer or interview call,
                please be alert on the likelihood of a scammer.
              </li>
              <li>
                The SNP Pvt. Ltd. Recruitment team does not request or require
                personal documents like bank account details, tax forms or
                credit card information as part of the recruitment process.
              </li>
            </ul>
            <p className={styles.sectionContent}>
              If you are in doubt or unsure about the source of the job offer,
              please login to the Career section on the SNP Pvt. Ltd. website to
              verify the job offer or the application.
            </p>
            <p className={styles.sectionContent}>
              If you believe you have been a victim of a recruitment fraud, you
              are requested to approach the law enforcement agencies
              immediately.
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CareersHome;
