// Overlay use className props to pass style properties to child component.
// To make this component work add className props to your child component manually.
// Here an example: https://gist.github.com/Miniplop/8f87608f8100e758fa5a4eb46f9d151f

import { Grid } from "@material-ui/core";
import React from "react";
import styles from "../scss/style.module.scss";
import image from "../assets/bulb.png";
const Header = () => {
  return (
    <div className={styles.heroSection}>
      <Grid container className={styles.heroContainer} spacing={2}>
        <Grid item md={6} sm={12} xs={12}>
          <div className={styles.heroTextWrapper}>
            <h1 className={styles.heroHeading}>
              Revolutionizing Digital Innovation
            </h1>
            <p className={styles.heroSubHeading}>
              We are fast growing organization making daily life simple for
              individuals and business.
            </p>
          </div>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <div className={styles.heroImageWrapper}>
            <img alt="idea" className={styles.heroImage} src={image} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;
