import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Collapse,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import useStyles from "../style";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsCondition";
import DialogBox from "../components/DialogBox";
import Loader from "../components/Loader";
import CrossIcon from "../assets/cross-icon.svg";
import ContactUsSevice from "../services/ContactUsSevice";

export default function ContactForm() {
  const classes = useStyles();

  const [contactDetails, setContactDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    city: "",
    zipCode: "",
    commentsType: "",
    comments: "",
    privacyAccepted: false,
  });

  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    " Please fill all the required details!"
  );
  const [firstNameError, setFirstNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [pinError, setPINError] = useState(false);
  const [commentsTypeError, setCommentsTypeError] = useState(false);
  const [commentsError, setCommentsError] = useState(false);
  const [privacyCheckedError, setPrivacyCheckedError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alertOpen, setAlertOpen] = useState(true);
  const handleChange = (event) => {
    setError(false);
    const { name, value } = event.target;
    setContactDetails({
      ...contactDetails,
      [name]: value,
    });
  };
  const clearContactDetails = () => {
    setContactDetails({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      city: "",
      zipCode: "",
      commentsType: "",
      comments: "",
      privacyAccepted: false,
    });
  };
  const handlePrivacyAccepted = (event) => {
    setContactDetails({
      ...contactDetails,
      privacyAccepted: event.target.checked,
    });
  };
  const renderDialogContent = () => {
    if (showPrivacy) {
      console.log("showing privacy policy....");
      return (
        <React.Fragment>
          <PrivacyPolicy />
        </React.Fragment>
      );
    } else if (showTerms) {
      return (
        <React.Fragment>
          <TermsConditions />
        </React.Fragment>
      );
    }
  };
  const renderDialogActions = () => {
    return (
      <Button variant="contained" color="primary" onClick={handleDialogClose}>
        Close
      </Button>
    );
  };
  const handleDialogClose = () => {
    setShowTerms(false);
    setShowPrivacy(false);
  };
  const validateForm = () => {
    let isError = false;
    if (contactDetails.firstName === "") {
      isError = true;
      setFirstNameError(true);
    }
    if (contactDetails.mobile === "") {
      isError = true;
      setMobileError(true);
    }
    if (contactDetails.email === "") {
      isError = true;
      setEmailError(true);
    }
    if (contactDetails.city === "") {
      isError = true;
      setCityError(true);
    }
    if (contactDetails.zipCode === "") {
      isError = true;
      setPINError(true);
    }
    if (contactDetails.commentsType === "") {
      isError = true;
      setCommentsTypeError(true);
    }
    if (contactDetails.comments === "") {
      isError = true;
      setCommentsError(true);
    }
    if (contactDetails.privacyAccepted === false) {
      isError = true;
      setPrivacyCheckedError(true);
    }
    return isError;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let isError = validateForm();
    setError(isError);
    setAlertOpen(false);
    if (!isError) {
      setBtnDisabled(true);
      setShowLoader(true);
      ContactUsSevice.saveUserContactDetailsData(contactDetails)
        .then((result) => {
          console.log("Data Saved Successfully....");
        })
        .catch((err) => {
          console.log("CSV: Error occured.. ", err);
          setError(true);
          setErrorMsg("Something went wrong! Please try again.");
          enableForm();
        });
      if (!error) {
        ContactUsSevice.sendMailToUser(contactDetails)
          .then((res) => {
            console.log("User mail sent Successfully....", res);
            setSuccess(true);
          })
          .catch((err) => {
            console.log("UMAIL: Error occured.. ", err);
            setError(true);
            setErrorMsg("Something went wrong! Please try again.");
            enableForm();
          });

        ContactUsSevice.sendInternalMail(contactDetails)
          .then((res) => {
            console.log("Internal mail sent Successfully....", res);
            clearContactDetails();
            setSuccess(true);
            setAlertOpen(true);
            enableForm();
          })
          .catch((err) => {
            console.log("IMAIL: Error occured.. ", err);
            setError(true);
            setErrorMsg("Something went wrong! Please try again.");
            enableForm();
          });
      }
    }
  };

  const enableForm = () => {
    setBtnDisabled(false);
    setShowLoader(false);
  };
  return (
    <>
      <form className={classes.contactForm}>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label="First name"
              size="small"
              value={contactDetails.firstName}
              onChange={handleChange}
              onFocus={() => {
                setError(false);
                setFirstNameError(false);
              }}
              error={firstNameError}
              fullWidth
              variant="outlined"
              autoComplete="given-name"
            />
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              size="small"
              value={contactDetails.email}
              onChange={handleChange}
              onFocus={() => {
                setError(false);
                setEmailError(false);
              }}
              error={emailError}
              fullWidth
              type="email"
              variant="outlined"
              autoComplete="shipping postal-code"
            />
            <TextField
              required
              id="city"
              name="city"
              label="City"
              type="text"
              variant="outlined"
              size="small"
              value={contactDetails.city}
              onChange={handleChange}
              fullWidth
              onFocus={() => {
                setError(false);
                setCityError(false);
              }}
              error={cityError}
              autoComplete="shipping postal-code"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label="Last Name"
              size="small"
              value={contactDetails.lastName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              autoComplete="family-name"
            />

            <TextField
              required
              id="mobile"
              name="mobile"
              label="Mobile No."
              size="small"
              value={contactDetails.mobile}
              onChange={handleChange}
              onFocus={() => {
                setError(false);
                setMobileError(false);
              }}
              error={mobileError}
              fullWidth
              variant="outlined"
              autoComplete="shipping country"
            />

            <TextField
              required
              id="zipCode"
              name="zipCode"
              label="PIN Code"
              type="text"
              variant="outlined"
              size="small"
              value={contactDetails.zipCode}
              onChange={handleChange}
              fullWidth
              onFocus={() => {
                setError(false);
                setPINError(false);
              }}
              error={pinError}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              required
              component="fieldset"
              error={commentsTypeError}
            >
              <FormLabel component="legend">Comment Type</FormLabel>
              <RadioGroup
                row
                aria-label="type"
                name="commentsType"
                value={contactDetails.commentsType}
                onChange={handleChange}
                onFocus={() => {
                  setError(false);
                  setCommentsTypeError(false);
                }}
              >
                <FormControlLabel
                  value="query"
                  control={<Radio color="primary" />}
                  label="Query"
                />
                <FormControlLabel
                  value="issue"
                  control={<Radio color="primary" />}
                  label="Issue"
                />
                <FormControlLabel
                  value="feedback"
                  control={<Radio color="primary" />}
                  label="FeedBack"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="comments"
              name="comments"
              label="Comments"
              value={contactDetails.comments}
              onChange={handleChange}
              onFocus={() => {
                setError(false);
                setCommentsError(false);
              }}
              fullWidth
              multiline
              maxRows={5}
              variant="outlined"
              error={commentsError}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={contactDetails.privacyAccepted}
                    onChange={handlePrivacyAccepted}
                    onFocus={() => {
                      setError(false);
                      setPrivacyCheckedError(false);
                    }}
                    error={privacyCheckedError}
                    name="privacyAccepted"
                    color="primary"
                    className={classes.checkbox}
                  />
                }
                label={
                  <div className={classes.checkboxLabel}>
                    <span>I accept the </span>
                    <button
                      type="button"
                      onClick={() => {
                        setShowPrivacy(true);
                      }}
                    >
                      Privacy Policy
                    </button>
                    <span> and </span>
                    <button
                      type="button"
                      onClick={() => {
                        setShowTerms(true);
                      }}
                    >
                      Terms & Condition
                    </button>
                  </div>
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            {showLoader && <Loader />}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={btnDisabled}
            >
              Submit
            </Button>
            <br />
            {error && (
              <Alert variant="outlined" severity="error">
                {errorMsg}
              </Alert>
            )}
            {success && (
              <Collapse in={alertOpen}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlertOpen(false);
                      }}
                    >
                      {/* <CloseIcon fontSize="inherit" /> */}
                      <img
                        alt="SNP"
                        src={CrossIcon}
                        height="18px"
                        width="22px"
                        style={{filter: 'invert(0.8)'}}
                      />
                    </IconButton>
                  }
                >
                  Thanks for Contacting!
                </Alert>
              </Collapse>
            )}
          </Grid>
        </Grid>
        <DialogBox
          showDialog={showPrivacy || showTerms}
          dialogContent={renderDialogContent()}
          dialogActions={renderDialogActions()}
          onClose={handleDialogClose}
        ></DialogBox>
      </form>
    </>
  );
}
