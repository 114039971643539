/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import {
  AppBar,
  CssBaseline,
  Toolbar,
  Button,
} from "@material-ui/core";

import styles from "../../scss/style.module.scss";
import logo from "../../assets/snp_logo-1.png";
import MenuIcon from "../../assets/menu-icon.svg";
import CrossIcon from "../../assets/cross-icon.svg";
import { NavItems } from "./navItems";
import useStyles from "../../style";

export default function NavBar(props) {
  const classes = useStyles();
  let listener = null;
  const [scrollState, setScrollState] = useState("top");
  const [menuClicked, setMenuClicked] = useState(false);
  useEffect(() => {
    listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 100) {
        if (scrollState !== "scroll") {
          setScrollState("scroll");
        }
      } else {
        if (scrollState !== "top") {
          setScrollState("top");
        }
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [scrollState]);

  //const btnColor = scrollState === "top" ? "primary" : "secondary";

  // let liClasses = classNames({
  //   styles.navMenu : true,
  //   styles.navMenuMobile: menuClicked
  // });
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position={props.position}
        color={scrollState === "top" ? `${props.color}` : "primary"}
      >
        <Toolbar>
          <IconButton onClick={() => (window.location.href = "/")}>
            <img height="40px" width="40px" alt="SNP" src={logo} />
          </IconButton>
          <h1 className={styles.appBarTitle} onClick={() => (window.location.href = "/")}>SPARK N PASS NEXT GEN</h1>

          <IconButton className={classes.mobileNavIcon} onClick={() => setMenuClicked(!menuClicked)}>
              <img
                alt="SNP"
                src={menuClicked ? CrossIcon : MenuIcon}
                height="28px" width="32px"
              />
            </IconButton>
  
            <ul className={menuClicked?  styles.navMenu+' '+ styles.active: styles.navMenu}>
              {         
              NavItems.map((item, index) => {
                return (
                  <li key={index}>
                    <a href={item.url} className={item.clsName}>{item.title}</a>
                  </li>
                );
              })}
            </ul>
            {/* <nav>
              <Link variant="button" color="textPrimary" href="/investor">
                Investor
              </Link>

              <Link variant="button" color="textPrimary" href="/franchise">
                Franchise
              </Link>

              <Link variant="button" color="textPrimary" href="/business">
                Business Arena
              </Link>
            </nav> */}
            <Button
              variant="contained"
              //color={window.location.pathname !== "/" ? "secondary" : btnColor}
              className={classes.navBtn}
              onClick={()=>window.location.href="/contact-us"}
            >
              Contact Us
            </Button>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
