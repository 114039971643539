import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
} from "@material-ui/core";
import React from "react";
import styles from "../scss/style.module.css";
import img1 from "../assets/jcu_logo.jpeg";
import img2 from "../assets/ekart_logo1.png";
import useStyles from "../style";

function Products() {
  const classes = useStyles();
  return (
    <div className={styles.productSection}>
      <h2 className={styles.sectionTitle}>Our Products</h2>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.productItem}
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          // }}
        >
          <Card elevation={0}>
            <CardActionArea
              onClick={() =>
                (window.location.href = "http://justconnectunite.com")
              }
            >
              <CardMedia component="img" src={img1} alt="JCU" />
              <CardContent>
                <h3 className={styles.cardTitle}>Just Connect Unite</h3>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.productItem}
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          // }}
        >
          <Card
            style={{
              paddingTop: "20px",
              width: "300px",
              backgroundColor: "#635e5e29",
              margin: "20px 0px",
              borderRadius: "10px",
            }}
            elevation={0}
          >
            <CardMedia
              component="img"
              style={{ marginLeft: "auto", marginRight: "auto"}}
              src={img2}
              alt="E-Kart"
            />
            <CardContent>
              <h3 className={styles.cardTitle} style={{paddingTop:'15px',marginBottom: '2px'}}>E-Kart</h3>
              <h5 style={{ textAlign: "center", margin: '1px' }}>Coming Soon...</h5>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default Products;
