import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import NavBar from "./components/NavBar/NavBar";
import BusinessArena from "./pages/BusinessArena";
import ElectronicsSection from "./pages/ElectronicsSection";
import Franchise from "./pages/Franchise";
import GreenGasSection from "./pages/GreenGasSection";
import Home from "./pages/Home";
import Investor from "./pages/Investor";
import ITSection from "./pages/ITSection";
import Footer from "./pages/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsCondition";
import CareersHome from "./pages/CareersHome";
import Works from "./pages/OurWorks";
import Contact from "./pages/Contact";

const theme = createTheme({
  // "@global": {
  //   body: {
  //     backgroundColor: 'green'

  //   }
 // },
  palette: {
    primary: {
      main: "rgba(1, 51, 62, 1)",
    },
    secondary: {
      main: "rgba(47, 17, 96, 1)",
    },
    background: {
      paper: '#ffffff',
      default: "#ffffff"
    },
  },
  components: {
    // Name of the component
    MuiContainer: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: 'white',
        },
      },
    },
  },
});

export default function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <NavBar
          appTitle={"Spark N Pass Next Gen"}
          color={window.location.pathname === "/" ? "transparent" : "primary"}
          position={"fixed"}
        />

        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/business" exact component={BusinessArena} />s
          <Route path="/investor" exact component={Investor} />
          <Route path="/franchise" exact component={Franchise} />
          <Route path="/contact-us" exact component={Contact} />
          <Route path="/careers" exact component={CareersHome} />
          <Route path="/it" exact component={ITSection} />
          <Route path="/electronics" exact component={ElectronicsSection} />
          <Route path="/green-gas" exact component={GreenGasSection} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/terms-condition" exact component={TermsConditions} />
          <Route path="/our-works" exact component={Works} />
        </Switch>

        <Footer />
      </ThemeProvider>
    </Router>
  );
}
