import { Container } from "@material-ui/core";
import React from "react";
import About from "./About";
import Careers from "./Careers";
import Contact from "./Contact";
import Header from "./Header";
import OurJourney from "./OurJourney";
import Products from "./Products";

export default function Home() {
  return (
    <React.Fragment>
       <Header />
      <Container style={{backgroundColor: "white"}} maxWidth="xl">
        <About />
        <OurJourney />
        <Products/>
        <Careers />
        <Contact />
      </Container>
    </React.Fragment>
  );
}
