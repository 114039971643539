
import styles from "../../scss/style.module.scss";

export const NavItems = [
    {
        title: "Our Works",
        url: "/our-works",
        clsName: styles.navLink
    },
    {
        title: "Investor",
        url: "/investor",
        clsName: styles.navLink
    },
    {
        title: "Franchise",
        url: "/franchise",
        clsName: styles.navLink
    },
    {
        title: "Business Arena",
        url: "/business",
        clsName: styles.navLink
    },
    {
        title: "Contact Us",
        url: "/contact-us",
        clsName: styles.navLinkMobile
    }
]