export const workItems = [
    {
        title: "Just Connect Unite",
        content:"JCU provides Innovative, Smart and easy lifestyle to ‘B-Business’ and ‘C-Customers’. It itself is like a ‘Design of dress’, ‘Way of Presentation’, ‘Ease of Depiction’, ‘Lifestyle that’s today's need’ and ‘Requirement that engulfs the gap’. WHY JCU APP if there are lots of BEST APP in the market? Answer to this question like construction of Eiffel Tower, but actually the answer lies in facts “IF YOU INSTALL APP, HE (JCU) himself WILL CONVEY WHAT, WHO and WHY I needed in this APP in your LIST. JCU provides a digital platform where most of the day-to-day necessary services and products in your local area will be easily available on your fingertips.Download, Open and feel the ‘ZEAL’.",
        image: "/images/jcu.jpg",
        url:"http://www.justconnectunite.com"
    },
    {
        title: "Inventory Management",
        content:"Inventory management helps companies identify which and how much stock to order at what time. It tracks inventory from purchase to the sale of goods. The practice identifies and responds to trends to ensure there’s always enough stock to fulfill customer orders and proper warning of a shortage. It is the process of ordering, storing, using, and selling a company's inventory. This includes the management of raw materials, components, and finished products, as well as warehousing and processing of such items. We provide features like Goods In/Out, Purchase request/return, Product transfer, Expiry alert, Sales order, Reports, Bill managements and Customizable dashboard etc.",
        image: "/images/Inventory.jpg",
    },
    {
        title: "Asset Management",
        content:"Managing your organization’s assets of all types, unlocks efficiency and greater accountability. Various types of asset management will help you gather more information about the best system for your business and make a good decision. We will help you in developing a good fit for the nature of your company.This systems helps in understanding and monitor an asset's lifecycle, Track assets and remove ghost assets, Identify and manage risk and Manage assets from any location. We provide Digital Asset Management, Fixed Asset Management,IT Asset Management, Enterprise Asset Management,Financial Asset Management,Infrastructure Asset Management etc.",
        image:"/images/asset.jpg",
    },
    {
        title: "Invoicing and Billing Management",
        content:"An invoice or bill is an important document that indicates the sale or supply by one to another business or consumer and contains information about the particular sale transaction, such as buyer’s details, quantity, value, tax, and payment terms. We provide Complete billing, invoicing, and taxation to support any business or pricing model which enables enterprises to launch and monetize any combination of subscription, usage-based billing models. With automated billing management, you will maximize recurring revenue and improve the customer experience. This tool also provides analytics and customized dashboard.",
        image: "/images/invoicing.jpg",
    },
    {
        title: "Service Role Management",
        content:"Service Management focuses on providing value to the customer and also on the customer relationship. Service management has different roles like Service Owner and Service managers etc. Service Owner is the ultimate responsible person for a specific service inside the organization and a key player in service management. Service Manager helps in Monitoring department issues, client complaints. developing problem management and service improvement plans and Oversees internal supplier management. It will help in managing SLA with customers and external service providers.It Manages delivery and lifecycle of services for business strategy development. It also manage vendor relations and inventory related to the service and cost management.",
        image: "/images/service-role-2.png",
    },
    {
        title: "Employee Management",
        content:"Employee engagement systems are structured to accurately monitor, assess, and control the working hours of workers and to efficiently utilize the human resources. Also, it helps in ensuring the payrolls are done on a timely basis. Employee Management System helps to eliminate the manual process and saves a lot of time and money. This system maintains the professional and personal details of the employees and the company in a safe manner. The employee management system lowers the burden and the pressure on HRs and the business managers. This tool also include  features like Add/manage employee, employee details and designations, work reports, performance analysis and  Announcements or Birthdays.",
        image: "/images/emp_mgmnt.png",
    },
    {
        title: "HRMS Complete Solution",
        content:"Human Resource Management System(HRMS) is a solution designed to help your HR to reduce specific time-consuming and unproductive functions by replacing it with an automated solution. It provides various HR functions like employee management, Gathering, storing, and accessing employee information, payroll activities, recruiting and onboarding, performance evaluation, statutory compliance, and many more, with software technology.It ensures to ease the work of HR rather than rely on pen and paper or spread-sheet-based workflow, to increase business productivity and efficiency.",
        image: "/images/hrms.jpg",
    },
    {
        title: "Leave and Attendance Management",
        content:"Leave and Attendance Management System helps businesses to manage the holidays and provides best way to give a perfect work life balance to employees. If your employees are satisfied with their working ecosystem and having the benefit of good holiday management then you are definitely creating a good ecosystem. It also manage your daily attendance records for all employees and provide features like Marking attendance, Request Leave, Track Attendance and Reports etc.",
        image: "/images/leave-mgmnt-1.png",
    },
    {
        title: "HR Payroll Management",
        content:"Payroll Management System helps employer in managing  the salaries of the employees. The salary structure includes salary, allowances, deductions, and net payable to the employees. Helps in the generation of pay slips. Complicated Computations that are performed manually can be automated using this tool and It also help in various activities such as payroll processing, maintaining salary details of every employee in the organization, keeping track of deductions, time and attendance, loans, allowances, and bonuses. This tool computes employee take-home pay based on time records, benefits, and taxes.",
        image: "/images/payroll.jpg",
    },
    {
        title: "Hospital Management - Complete ERP Solution",
        content:"Hospital Management provide hospitals a finest way of managing their organization. This tools helps in patient care and management by providing awesome features like easy & quick patient registration, instant access to patient's complete health hsitory, integrated patient module with various other related departments and many more. This tool will provide all the necessary end-to-end functionality required to Hospitals like Patient management, staff management, HR & Payroll, OT Management, Emenrgency management, Doctors, Lab management, Blood Bank etc.",
        image: "/images/hospital.jpg",
    }
]


// This tool provides analytics and customized dashboard.