import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  Typography,
} from "@material-ui/core";
import styles from "../scss/style.module.css";

export default function WorkItem(props) {
  console.log("workProps: ", props);
  return (
    <Card style={{ maxWidth: 745, minHeight: 650 }} elevation={10}>
      <CardMedia
        height={260}
        component="img"
        image={process.env.PUBLIC_URL + props.image}
        alt="JCU"
      />
      <CardContent>
        <h3 className={styles.cardTitle}>{props.title}</h3>
        <p className={styles.cardDescription}>{props.content}</p>
      </CardContent>
      <CardActions>
        {props.url === undefined || props.url === null || props.url === "" ? (
          <div style={{padding: '0 0 9px 9px'}}>
          <Typography variant="button" align="center" gutterBottom>
            In Progress...
          </Typography>
          </div>
        ) : (
          <Button
            size="small"
            color="primary"
            onClick={() => (window.location.href = props.url)}
          >
            Learn More
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
