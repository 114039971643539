import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import styles from "../scss/style.module.scss";
//import { makeStyles } from "@material-ui/styles";
import it1 from "../assets/it-1.jpg";
import it2 from "../assets/it-2.jpg";
import it3 from "../assets/it-3.jpg";
import it4 from "../assets/it-4.jpg";
import it5 from "../assets/it-5.jpg";

// const useStyles = makeStyles({
//   root: {
//     minWidth: 445,
//     // borderRadius: "225px",
//     // alignSelf: "center",
//     // alignContent: "center",
//   },
// });

function ITSection() {
 // const classes = useStyles();

  return (
    <div className={styles.itSection}>
      <h2 className={styles.sectionTitle}>IT Industry</h2>

      <Grid container>
        <Grid item>
          <div className={styles.subSectionDescWrapper}>
              <p className={styles.subSectionDescription}>
                From Frontend - ‘Mobile Application’, ‘Web Application’ to
                Backend - ‘Application Integration’ on a server based on
                ‘Requirement gathered’.
                <br />
                Our application software developers are industry-specific
                technology experts with cross-platform experience delivering
                interoperable, scalable, and flexible web & mobile applications
                for all kinds of industries.
                <br />
                Those who are interested can contact us at
                it_application@snpnextgen.com with their requirements.
              </p>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} sm={6} xs={12}>
          <Card elevation={15}>
            <CardContent>
              <h3 className={styles.cardTitle}>
                Application Development Services
              </h3>
              <p className={styles.cardDescription}>
                SNPians develops applications that are built with a robust set
                of features that are custom-tailored to fit your brand. These
                scalable, flexible, and interoperable applications can help you
                run your business more smoothly and efficiently.
              </p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Card elevation={15}>
            <CardContent>
              <h3 className={styles.cardTitle}>
                Cloud Application Development
              </h3>
              <p className={styles.cardDescription}>
                We conceptualize, design, and develop innovative Cloud
                Applications that deliver exceptional agility, performance, and
                interoperability while offering legacy app modernization, IoT
                solutions, cloud-native architectures, microservices application
                architecture, cloud-ready app migration, and more.
              </p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Card elevation={15}>
            <CardContent>
              <h3 className={styles.cardTitle}>UX/UI Design & Development</h3>
              <p className={styles.cardDescription}>
                We transform customer experiences across multiple digital
                devices and internet channels. Our UX/UI design experts utilize
                the latest user experience & interface (UX/UI) technologies,
                architectures, configurations, and other development best
                practices to deliver added functionality to your custom app.
              </p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Card elevation={15}>
            <CardContent>
              <h3 className={styles.cardTitle}>API Integrations</h3>
              <p className={styles.cardDescription}>
                We design multifarious API using Industry upgraded technology to
                extend your existing app’s functionality, enhance performance,
                and so much more.
              </p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Card elevation={15}>
            <CardContent>
              <h3 className={styles.cardTitle}>Data Migration to Cloud</h3>
              <p className={styles.cardDescription}>
                We have experts who specialize in Cloud Migration, Database
                migration from Legacy Services/Technology, combining all of your
                critical data from multiple platforms into a secure, single
                point of access and a bilateral redundancy allowing you to view
                your data in real-time on a centralized system and maintain data
                integrity and creating redundancy for catastrophe.
              </p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Card elevation={15}>
            <CardContent>
              <h3 className={styles.cardTitle}>
                Client-Facing Application Solutions
              </h3>
              <p className={styles.cardDescription}>
                SNP’s agile development practices allow us to create innovative,
                highly scalable Client-Facing Application Solutions that offer
                more interoperability and flexibility than most off-the-shelf
                solutions currently on the market. At Spark N Pass Next Gen, we
                build powerful client app solutions to fuel business growth and
                maximize your ROI.
              </p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Card elevation={15}>
            <CardContent>
              <h3 className={styles.cardTitle}>
                Custom Mobile Application Solutions
              </h3>
              <p className={styles.cardDescription}>
                We create custom Mobile Application Solutions for B2B and B2C
                companies/Shop/Service Firm to drive their Business in Digital
                way and create new revenue streams and maximize existing revenue
                streams. We can develop Business custom application solutions
                from the ground up or enhance existing applications with art of
                upgraded technology and integrations for added functionality,
                flexibility, and scalability.
              </p>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <div className={styles.businessSubSectionImageContainer}>
      
        <div className={styles.img1}>
          <img src={it1} alt="It 1" />
        </div>
        <div className={styles.img2}>
          <img src={it2} alt="It 2" />
        </div>
        <div className={styles.img3}>
          <img src={it3} alt="It 3" />
        </div>
        <div className={styles.img4}>
          <img src={it4} alt="It 4" />
        </div>
        <div className={styles.img5}>
          <img src={it5} alt="It 5" />
        </div>
        <div className={styles.img1}>
          <img src={it1} alt="It 1" />
        </div>
        <div className={styles.img2}>
          <img src={it2} alt="It 2" />
        </div>
        <div className={styles.img3}>
          <img src={it3} alt="It 3" />
        </div>
        {/* <div className={styles.img6}>
        <img
            src={it6}
            alt="It 6"
          />
        </div> */}
      </div>
    </div>
  );
}

export default ITSection;
