import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import styles from "../scss/style.module.scss";
import ItImage from "../assets/it.jpg";
import ElectronicsImage from "../assets/circuit_board.jpg";
import GreenImage from "../assets/biogas.jpg";
const useStyles = makeStyles({
  root: {
    width: 450,
    alignSelf: "center",
  },
  media: {
    height: 260,
  },
  gridItem : {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

function BusinessArena() {
  const classes = useStyles();
  return (
    <div className={styles.businessSection}>
      <h2 className={styles.sectionTitle}>Business Arena</h2>
      <br/>
      <Grid
        container
        spacing={8}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item md={6} xs={12} className={classes.gridItem}  >
          <Card className={classes.root} elevation={15}>
            <CardActionArea onClick={() => (window.location.href = "/it")}>
              <CardMedia
                className={classes.media}
                image={ItImage}
                title="IT Industry"
              />
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={() => (window.location.href = "/it")}
              >
                IT Industry
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item md={6} xs={12} className={classes.gridItem} >
          <Card className={classes.root} elevation={15} >
            <CardActionArea onClick={() => (window.location.href = "/electronics")}>
              <CardMedia
                className={classes.media}
                image={ElectronicsImage}
                title="Electronics Industry"
              />
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={() => (window.location.href = "/electronics")}
              >
                Electronics Application Industry
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item md={6} xs={12} className={classes.gridItem} >
          <Card className={classes.root} elevation={15}>
            <CardActionArea onClick={() => (window.location.href = "/green-gas")}>
              <CardMedia
                className={classes.media}
                image={GreenImage}
                title="Green Industry"
              />
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={() => (window.location.href = "/green-gas")}
              >
                Green Gas Industry
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default BusinessArena;
