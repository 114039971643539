import React from "react";
import { Grid } from "@material-ui/core";
import e1 from "../assets/e-1.jpg";
import e2 from "../assets/e-2.jpg";
import e3 from "../assets/e-3.jpg";
import e4 from "../assets/e-4.jpg";
import e5 from "../assets/e-5.jpg";

import styles from "../scss/style.module.scss";

function ElectronicsSection() {
 
  return (
    <div className={styles.electronicSection}>
      <h2 className={styles.sectionTitle}>
        Electronics Application
        <br /> Industry
      </h2>

      <Grid container>
        <Grid item>
          <div className={styles.subSectionDescWrapper}>
            <p className={styles.subSectionDescription}>
              From IT-Social Solution to Business Devices for industry support.
              Our experts are in the R & D arena and mainly work in development
              of Electronic devices which could bring Smartness in the life of
              People and Utilization of Electronic Technology in generating a
              world with Electronic Devices a support to Peoples, Country,
              World.
              <br />
              We are developing a Smart Home with Complete Security Solution
              which has multiple features.The one pioneer feature embarks the
              integration in existing Houses/Shops with minimal changes which
              shows our design algorithm dynamicity.
              <br />
              Those who interested in this technology reach us on
              electronic_application@snpnextgen.com.
            </p>
          </div>
        </Grid>
      </Grid>

      <div className={styles.businessSubSectionImageContainer}>
        <div className={styles.img1}>
          <img src={e1} alt="Electronics 1" />
        </div>
        <div className={styles.img2}>
          <img src={e2} alt="Electronics 2" />
        </div>
        <div className={styles.img3}>
          <img src={e3} alt="Electronics 3" />
        </div>
        <div className={styles.img4}>
          <img src={e4} alt="Electronics 4" />
        </div>
        <div className={styles.img5}>
          <img src={e5} alt="Electronics 5" />
        </div>
      </div>
    </div>
  );
}

export default ElectronicsSection;
