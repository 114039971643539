
export const BASE_JCU_SITE_SERVICE_URL = "http://164.52.192.201:8010";
//export const BASE_JCU_SITE_SERVICE_URL = "http://localhost:8010";

export const SAVE_CONTACTUS_DATA_ENDPOINT = "/contacts/user";
export const SAVE_CAREERS_EMPLOYEE_DATA_ENDPOINT = "/careers/employee";

export const IMAGE_UPLOAD_ENDPOINT = "http://app.justconnectunite.com:3030/passWeb/jc-img-upd"
export const JCU_MAIL_SENDER_API_ENDPOINT="http://app.justconnectunite.com:3030/passWeb/jcmailsender"

//export const JCU_MAIL_SENDER_API_ENDPOINT = "http://app.justconnectunite.com:3031/passWeb_test";