import { Grid } from "@material-ui/core";
import React from "react";
import styles from "../scss/style.module.scss";
import image from "../assets/investors.jpg";
const Investor = () => {
  return (
    <div className={styles.investorSection}>
      <h2 className={styles.sectionTitle}>Investor</h2>
      <Grid container className={styles.flexWrapperOne}>
        <Grid item md={6} lg={6} sm={12}>
          <div className={styles.sectionImageWrapper}>
            <img alt="" className={styles.sectionImage} src={image} />
          </div>
        </Grid>
        <Grid item md={6} lg={6} sm={12}>
          <div className={styles.sectionTextWrapper}>
            <p className={styles.sectionContent}>
              We as a company believe in diversity and are always ready to work
              with new investors in different fields. A investor can join us in
              different way.
            </p>
            <ul>
              <li>
                As a existing business continuation partner in one or more area.
              </li>
              <li>
                A partner with their business want to run as Joint Venture.
              </li>
              <li>
                A partner to invest in a new arena and run as a Joint Venture or
                Partner with us.
              </li>
              <li>
                A Partner to invest in their Country with region specific
                approach with us.{" "}
              </li>
            </ul>
            <p className={styles.sectionContent}>
              For more details investor can reach us at investor@snpnextgen.com{" "}
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Investor;
