import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function DialogBox(props) {
  const {
    showDialog = false,
    onClose = () => {},
    title = "",
    dialogContent = "",
    dialogActions = "",
    children,
  } = props;

  const handleClose = (event) => {
    onClose(event);
  };

  return (
    <div>
      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form>
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>{dialogContent}</DialogContent>
          <DialogActions>{dialogActions}</DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
