import React from "react";
import { Grid } from "@material-ui/core";
import styles from "../scss/style.module.scss";
import gg1 from "../assets/gg-1.jpg";
import gg2 from "../assets/gg-2.jpg";
import gg3 from "../assets/gg-3.jpg";
import gg4 from "../assets/gg-4.jpg";
import gg5 from "../assets/gg-5.jpg";

function GreenGasSection() {

  return (
    <div className={styles.greenGasSection}>
      <h2 className={styles.sectionTitle}>Green Gas Industry</h2>

      <Grid container>
        <Grid item>
          <div className={styles.subSectionDescWrapper}>
            <p className={styles.subSectionDescription}>
              Our experts in R & D are working on harnessing technology for large scale Green Gas production.
              We are working with our investors to set-up a factory for Green Gas Production at mass level.
              <br />
              Those who interested in this technology reach us on green_gas@snpnextgen.com.
            </p>
          </div>
        </Grid>
      </Grid>
      <div className={styles.businessSubSectionImageContainer}>
      <div className={styles.img1}>
          <img src={gg1} alt="Electronics 1" />
        </div>
        <div className={styles.img2}>
          <img src={gg2} alt="Electronics 2" />
        </div>
        <div className={styles.img3}>
          <img src={gg3} alt="Electronics 3" />
        </div>
        <div className={styles.img4}>
          <img src={gg4} alt="Electronics 4" />
        </div>
        <div className={styles.img5}>
          <img src={gg5} alt="Electronics 5" />
        </div>
      </div>
    </div>
  );
}

export default GreenGasSection;
