import React from "react";
import { Grid } from "@material-ui/core";
import styles from "../scss/style.module.scss";
import image from "../assets/contact_us.jpg";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <div className={styles.contactSection} id="contact">
      <h2 className={styles.sectionTitle}>Contact Us</h2>
      <Grid container>
        <Grid item md={6} lg={6} sm={12}>
          <ContactForm />
        </Grid>
        <Grid
          item
          md={6}
          lg={6}
          sm={12}
          className={styles.sectionImageContainer}
        >
          <div className={styles.sectionImageWrapper}>
            <img alt="" className={styles.sectionImage} src={image} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;
